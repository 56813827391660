export const normalize = (value: string): string => {
  const from  = "àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßàáâãäåæçèéêëìíîïðñòóôõöøùúûýýþÿŕŕ";
  const to = "aaaaaaaceeeeiiiidnoooooouuuuybsaaaaaaaceeeeiiiidnoooooouuuyybyrr";

  let aux = value || ''
  for (let i = 0; i < from.length; i++) {
    const char_re = new RegExp(from.charAt(i), "gim");
    aux = aux.toLowerCase().replace(char_re, to.charAt(i))
  };

  return aux.replace(/[^\w\s]/gi, ' ').split(' ').filter(Boolean).join(' ').toLowerCase();
}

export const sortArrayText = (array:Array<string>) => {
  return array.sort((a,b)=>{
  if (normalize(a) < normalize(b))
    return -1;
  if (normalize(a) > normalize(b))
    return 1;
  return 0;})
}

export const transformDate = (start, end) => {
  const t = Date.parse(end) - Date.parse(start)
  return new Date(t).toISOString().split('T')[1].split('.')[0]
  // const secNum = parseInt((value || 0).toString(), 10)
  // const hours   = Math.floor(secNum / 3600)
  // const minutes = Math.floor((secNum - (hours * 3600)) / 60)
  // const seconds = secNum - (hours * 3600) - (minutes * 60)

  // const hh = hours < 10 ? '0' + hours : hours.toString()
  // const mm = minutes < 10 ? '0' + minutes : hours.toString()
  // const ss = seconds < 10 ? '0' + seconds : hours.toString()

  // return new Date(Number(secNum) * 1000).toISOString().split('T')[1].split('.')[0]
}
