import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { ChartBarCardComponent } from './chart-bar-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [ChartBarCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule
  ],exports:[
    ChartBarCardComponent
  ]
})
export class ChartBarCardModule { }
