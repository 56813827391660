import { normalize } from './../shared_components/utils/tools';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProvincesService {

  constructor() { }

  readonly codProvincias = {
    "10": "Cáceres",
    "11": "Cádiz",
    "12": "Castelló",
    "13": "Ciudad Real",
    "14": "Córdoba",
    "15": "A Coruña",
    "16": "Cuenca",
    "17": "Girona",
    "18": "Granada",
    "19": "Guadalajara",
    "20": "Gipuzcoa",
    "21": "Huelva",
    "22": "Huesca",
    "23": "Jaén",
    "24": "León",
    "25": "Lleida",
    "26": "La Rioja",
    "27": "Lugo",
    "28": "Madrid",
    "29": "Málaga",
    "30": "Murcia",
    "31": "Navarra",
    "32": "Ourense",
    "33": "Asturias",
    "34": "Palencia",
    "35": "Las Palmas",
    "36": "Pontevedra",
    "37": "Salamanca",
    "38": "Santa Cruz de Tenerife",
    "39": "Cantabria",
    "40": "Segovia",
    "41": "Sevilla",
    "42": "Soria",
    "43": "Tarragona",
    "44": "Teruel",
    "45": "Toledo",
    "46": "València",
    "47": "Valladolid",
    "48": "Bizkaia",
    "49": "Zamora",
    "50": "Zaragoza",
    "51": "Ceuta",
    "52": "Melilla",
    "05": "Ávila",
    "08": "Barcelona",
    "02": "Albacete",
    "09": "Burgos",
    "04": "Almería",
    "01": "Araba",
    "07": "Illes Balears",
    "06": "Badajoz",
    "03": "Alacant"
  }

  readonly codCCAA = {
    "10": "Comunidad Valenciana",
    "11": "Extremadura",
    "12": "Galicia",
    "13": "Comunidad de Madrid",
    "14": "Región de Murcia",
    "15": "Comunidad Foral de Navarra",
    "16": "País Vasco",
    "17": "La Rioja",
    "18": "Ceuta",
    "19": "Melilla",
    "01": "Andalucía",
    "07": "Castilla y León",
    "04": "Islas Baleares",
    "03": "Principado de Asturias",
    "06": "Cantabria",
    "08": "Castilla - La Mancha",
    "09": "Cataluña",
    "02": "Aragón",
    "05": "Canarias"
  }

  readonly provincias = {
        'A Coruña':'A Coruña',
        'Álava':'Álava',
        'Albacete':'Albacete',
        'Alicante':'Alicante',
        'Almería':'Almería',
        'Asturias':'Asturias',
        'Ávila':'Ávila',
        'Badajoz':'Badajoz',
        'Baleares':'Baleares',
        'Barcelona':'Barcelona',
        'Burgos':'Burgos',
        'Cáceres':'Cáceres',
        'Cádiz':'Cádiz',
        'Cantabria':'Cantabria',
        'Castellón':'Castellón',
        'Ciudad Real':'Ciudad Real',
        'Córdoba':'Córdoba',
        'Cuenca':'Cuenca',
        'Girona':'Girona',
        'Granada':'Granada',
        'Guadalajara':'Guadalajara',
        'Gipuzkoa':'Gipuzkoa',
        'Huelva':'Huelva',
        'Huesca':'Huesca',
        'Jaén':'Jaén',
        'La Rioja':'La Rioja',
        'Las Palmas':'Las Palmas',
        'León':'León',
        'Lleida':'Lleida',
        'Lugo':'Lugo',
        'Madrid':'Madrid',
        'Málaga':'Málaga',
        'Murcia':'Murcia',
        'Navarra':'Navarra',
        'Ourense':'Ourense',
        'Palencia':'Palencia',
        'Pontevedra':'Pontevedra',
        'Salamanca':'Salamanca',
        'Segovia':'Segovia',
        'Sevilla':'Sevilla',
        'Soria':'Soria',
        'Tarragona':'Tarragona',
        'Santa Cruz de Tenerife':'Santa Cruz de Tenerife',
        'Teruel':'Teruel',
        'Toledo':'Toledo',
        'Valencia':'Valencia',
        'Valladolid':'Valladolid',
        'Vizcaya':'Vizcaya',
        'Zamora':'Zamora',
        'Zaragoza':'Zaragoza',
        'Ceuta':'Ceuta',
        'Melilla':'Melilla'
  }

  provinciasCCAA = {
    'A Coruña': 'Galicia',
    'Álava': 'País Vasco',
    'Albacete': 'Castilla La Mancha',
    'Alicante': 'Comunidad Valenciana',
    'Almería': 'Andalucía',
    'Asturias': 'Principado de Asturias',
    'Ávila': 'Castilla y León',
    'Badajoz': 'Extremadura',
    'Baleares': 'Islas Baleares',
    'Barcelona': 'Cataluña',
    'Burgos': 'Castilla y León',
    'Cáceres': 'Extremadura',
    'Cádiz': 'Andalucía',
    'Cantabria': 'Cantabria',
    'Castellón': 'Comunidad Valenciana',
    'Ciudad Real': 'Castilla La Mancha',
    'Córdoba': 'Andalucía',
    'Cuenca': 'Castilla La Mancha',
    'Girona': 'Cataluña',
    'Granada': 'Andalucía',
    'Guadalajara': 'Castilla La Mancha',
    'Gipuzkoa': 'País Vasco',
    'Huelva': 'Andalucía',
    'Huesca': 'Aragón',
    'Jaén': 'Andalucía',
    'La Rioja': 'La Rioja',
    'Las Palmas': 'Islas Baleares',
    'León': 'Castilla y León',
    'Lleida': 'Cataluña',
    'Lugo': 'Galicia',
    'Madrid': 'Comunidad de Madrid',
    'Madird': 'Comunidad de Madrid',
    'Málaga': 'Andalucía',
    'Murcia': 'Región de Murcia',
    'Navarra': 'Comunidad Foral de Navarra',
    'Ourense': 'Galicia',
    'Palencia': 'Castilla y León',
    'Pontevedra': 'Galicia',
    'Salamanca': 'Castilla y León',
    'Segovia': 'Castilla y León',
    'Sevilla': 'Andalucía',
    'Soria': 'Castilla y León',
    'Tarragona': 'Cataluña',
    'Santa Cruz de Tenerife': 'Canarias',
    'Teruel': 'Aragón',
    'Toledo': 'Castilla La Mancha',
    'Valencia': 'Comunidad Valenciana',
    'Valladolid': 'Castilla y León',
    'Vizcaya': 'País Vasco',
    'Zamora': 'Castilla y León',
    'Zaragoza': 'Aragón',
    'Ceuta': 'Ceuta',
    'Melilla': 'Melilla'
  }

  readonly provincesValues = Object.values(this.provincias)
  getCCAA(provincia) {
    return this.provinciasCCAA[provincia]
  }

  filterAutocompleteProvince(control){
    const provincesName = this.provincesValues
    if(control === '-'){  return provincesName }
    return provincesName.filter( _el => {
      return  normalize(_el.toLowerCase()).replace(/ /g,'').indexOf(control.toLowerCase()) != -1
    })
  }
}
